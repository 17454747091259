// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#signup .signup-hero-left {
  width: auto;
}
#signup .signup-right.above {
  border: none;
}
#signup .signup-right.mb-32 {
  margin-bottom: 32px;
}
#signup ::ng-deep a {
  color: gray;
}
@media (max-width: 479px) {
  #signup ::ng-deep .div-block-21 {
    font-size: 12px;
  }
}

.user-hidden-blury {
  color: #525252;
  -webkit-user-select: none;
          user-select: none;
  filter: blur(4px);
}`, "",{"version":3,"sources":["webpack://./src/app/themes/ux.2.0/peopleSearch/nameSearch/signup.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAIE;EACE,mBAAA;AAFJ;AAOI;EACE,WAAA;AALN;AAWM;EADF;IAEI,eAAA;EARN;AACF;;AAaA;EACE,cAAA;EACA,yBAAA;UAAA,iBAAA;EACA,iBAAA;AAVF","sourcesContent":["#signup {\n  .signup-hero-left {\n    width: auto;\n  }\n\n  .signup-right.above {\n    border: none;\n  }\n\n  .signup-right.mb-32 {\n    margin-bottom: 32px;\n  }\n\n\n  ::ng-deep {\n    a {\n      color: gray;\n    }\n  }\n\n  ::ng-deep {\n    .div-block-21 {\n      @media (max-width: 479px) {\n        font-size: 12px;\n      }\n    }\n  }\n}\n\n.user-hidden-blury {\n  color: #525252;\n  user-select: none;\n  filter: blur(4px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
