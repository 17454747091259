import {Component, Input, Output, EventEmitter} from "@angular/core";
import {CommerceContent} from "../../../common/models/commerce/commerceContent";
import {UxHelper} from "../../../clientCommon/helper/uxHelper";
import {ServiceHelperService} from "../../../clientCommon/services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {BaseDirective} from "../../../clientCommon/directives/BaseDirective";
import {LogUtils} from "../../../common/utils/logUtils";
import {mapUtils} from "../../../common/utils/mapUtils";
import {redirectHelper} from "../../../clientCommon/helper/redirectHelper";
import {behaviorHelper} from "../../../clientCommon/helper/behaviorHelper";
import {PersonDetailComponentConfig} from "./PersonDetailComponentConfig";
import {isObject} from "util";
import {SearchedPerson} from "../../../common/custom/models/peopleSearch/searchedPerson";
import {peopleSearchContentHelper} from "../../../common/custom/peopleSearch/peopleSearchHelper";
import {customClientPaths} from "../../../common/custom/customPathHelpers";
import {peopleSearchProductKeys} from "../../../common/custom/peopleSearch/peopleSearchProductKeys";
import {objectUtils} from "../../../common/utils/objectUtils";
import {Note} from '../../../common/models/note';
import { NoteService, AuthenticationService } from "../../../clientCommon/services";
import {ToastrService} from "../../service/toastr.service";
import {BaseComponent} from "../../../clientCommon/components/BaseComponent";
import { SpyIdHelper } from "@clientCommon/helper/spyIdHelper";

declare var $:any;

@Component({
  template: ''
})
export class PersonDetail extends BaseDirective {
  @Input() person: SearchedPerson;
  @Input() commerceContent: CommerceContent;
  @Input() page;
  @Input() uxHelper: UxHelper;
  @Input() config: PersonDetailComponentConfig;
  @Input() pdfDownloadFlag: boolean = null;
  @Output() onReload = new EventEmitter<void>();

  // mapUrl = "https://maps.google.com/maps?t=k&z=9&ie=UTF8&iwloc=&output=embed&q=";
  googleMap = "https://www.google.com/maps/place/";
  personMapUrl;
  zoom = 12;
  derivedPerson: SearchedPerson;
  derivedConfig: PersonDetailComponentConfig;
  phonePersonObject: SearchedPerson;
  @Input() personListLimit = 14;
  candidateLimit = 15;
  toggleAssociates = "toggleAssociates";
  toggleRelatives = "toggleRelatives";
  idiOsmURL: string[] = [];

  @Input() addressNeighborLimit = 4;
  toggleAddress = "toggleAddress";

  existFlags: any = {};
  pdfFileName = "report.pdf";

  monitoringFlag = false;
  ads = {
    main_1: "",
    main_2: "",
    main_3: "",
  };

  notes: Note[] = [];
  publicNotes: Note[] = [];
  privateNotes: Note[] = [];

  privateNoteExpanded = [];
  publicNoteExpanded = [];

  brandName = '';
  customerPhone = '';

  premiumFields = false;
  totalPremiumCounts = 0;
  upsellEnabled = false;
  idProtectionEnabled = false
  idProtectionFeatures: any = {}
  breaches = [];
  alerts = [];
  //alertsView: { idx: string, is_view: boolean, id: string, img_src: string }[] = [];
  alertsView: { idx: string, id: string, is_view: boolean, img_src: string, alarm: any }[] = [];
  pastBreaches = [];
  spyIdHelper = new SpyIdHelper()
  PROVIDER_TYPE = {
    dwbEmailMonitoringGetFullResults: "DwbEmailMonitoringGetFullResults",
    dwbEmailMonitoringGetAlerts: "DwbEmailMonitoringGetAlerts"
  }
  PROVIDER_TYPES = {
    dwbEmailMonitoringEnrollment: "DwbEmailMonitoringEnrollment",
    dwbEmailMonitoringGetFullResults: "DwbEmailMonitoringGetFullResults",
    dwbEmailMonitoringGetAlerts: "DwbEmailMonitoringGetAlerts",
    customerMonitoringGetAlerts: "CustomerMonitoringGetAlerts",
  }
  constructor(
    public serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, activatedRoute);
  }

  init() {
    this.phonePersonObject = peopleSearchContentHelper.chooseCandidateSearchObject(
      this.commerceContent
    );
    this.config = this.initConfig(this.person, this.config);
    this.setExistFlag(this.person);
    this.totalPremiumCounts = 0;
    this.upsellEnabled = !!this.person.tempClient.upsellEnabled;
    if (this.person.tempClient.upsellEnabled) {
      this.premiumFields = Object.keys(this.person.tempClient.availableItems).length > 0;
      Object.keys(this.person.tempClient.availableItems).forEach((k) => {
        const item = this.person.tempClient.availableItems[k];
        if (item.count) {
          this.totalPremiumCounts += item.count;
        }
        if (item.keys) {
          this.totalPremiumCounts += item.keys.length;
        }
      })
    }
    return this.baseInit().then(() => {
      if (this.commerceContent?.productKey === peopleSearchProductKeys.nameSearch) {
        this.monitoringFlag = true;
      }

      if (this.commerceContent?.productKey === peopleSearchProductKeys.phoneSearch) {
        // tslint:disable-next-line:max-line-length
        if (this.phonePersonObject.addresses.length) {
          this.personMapUrl = this.serviceHelperService.domSanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q=" + this.phonePersonObject.addresses[0].city + ", " + this.phonePersonObject.addresses[0].state + "&t=k&z=9&ie=UTF8&iwloc=&output=embed");
        }
      }

      // pdf download
      if (this.pdfDownloadFlag !== false) {
        return this.serviceHelperService.peopleSearchService.canDownloadPdf(this.commerceContent).then((flag) => {
          if (flag) {
            this.pdfDownloadFlag = true;
          }

          // tslint:disable-next-line:max-line-length
          this.pdfFileName = this.serviceHelperService.peopleSearchService.getPdfDownloadLink(this.uxComposite, this.commerceContent);

          LogUtils.debug("PDF", this.pdfDownloadFlag, this.pdfFileName, this.commerceContent);
        }).catch((e: any) => {
          LogUtils.error("Error :: ",e);
        });
      }

    }).then(() => {
      this.ads.main_1 = this.uxHelper.getUxcompKey("ad.main.1");
      this.ads.main_2 = this.uxHelper.getUxcompKey("ad.main.2");
      this.ads.main_3 = this.uxHelper.getUxcompKey("ad.main.3");
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      const idProtectionFrontEnabled = this.uxHelper.getValue("comp.member.idProtection.enable") === 'true'? true: false;
      const idProtectionBackendEnabled = this.uxHelper.getValue("comp.member.idProtection.registration.enable") === 'true'? true: false;
      if (idProtectionBackendEnabled && idProtectionFrontEnabled) {
        this.idProtectionEnabled = true;
      }

      this.brandName = this.uxComposite.brandName;
      this.customerPhone = this.uxHelper.getValue('comp.brand.customer.phone');
      this.noteService.findNote(this.person?.extId).then(notes => {
        this.clearNotes(); // init method is being called multiple times resulting in duplicate notes, so clearing the notes for every call
        notes.forEach(note => {
          if (note.privateFlag) this.privateNotes.push(note)
          else this.publicNotes.push(note)
        });
        this.publicNoteExpanded = new Array(this.publicNotes.length).fill(true);
        this.privateNoteExpanded = new Array(this.privateNotes.length).fill(true);
      })

      this.alertsView = [];
      this.alerts = [];
      this.breaches = [];

      LogUtils.debug("personDetail", this.commerceContent);
      if (this.idProtectionEnabled) {
          const spyDwbCommerceContent: CommerceContent = this.commerceContent
            ?.tempClient
            ?.spyDwbCommerceContent ?? { raws: [] };

          for (const raw of spyDwbCommerceContent.raws) {
            if (raw.meta.type == this.PROVIDER_TYPE.dwbEmailMonitoringGetFullResults) {
              if (raw?.data?.contentInfo?.body?.result?.length > 0) {
                this.breaches = [...this.breaches, ...raw?.data?.contentInfo?.body?.result];
              }
            }
          }
          
          const alertRaws = spyDwbCommerceContent.raws.find(raw => (raw.meta.type == this.PROVIDER_TYPE.dwbEmailMonitoringGetAlerts));
          if (alertRaws) {
            for (const alert of alertRaws?.data?.alertsData) {
              let data = { 
                id: spyDwbCommerceContent._id,
                alert_year: alert.year_of_breach,
                alert_date_of_alert: alert?.alert_created_date,
                alert_email: alert.breached_email,
                alert_breach: alert.breach,
                alert_type: alert.breached_data_classes, // 'email',
                // alert_subject: alert.breached_email,
                // alert_date: Math.round(new Date(alert.alert_created_date).getTime()/1000),
                // alert_status: alert.alert_status,
                alert_text: alert.breach_description,
                alert_id: alert.alert_access_key
              };
              this.alerts.push(data);
            }
          } 
          
          for(const alert of this.alerts) {
            this.alertsView.push(
                {
                  idx: alert.alert_id, id: spyDwbCommerceContent._id, is_view: false, img_src: '/assets/spyandid/bottom_arrow.svg', alarm: alert
                }
            ); 
          }

          for(const alert of this.breaches) {
            let breachAlart = {
              id: spyDwbCommerceContent._id,
              alert_year: alert.year_of_breach,
              alert_date_of_alert: alert?.alert_created_date,
              alert_email: alert.breached_email,
              alert_breach: alert.breach,
              alert_type: alert.breached_data_classes, // 'email',
              // alert_subject: alert.breached_email,
              // alert_date: Math.round(new Date(alert.alert_created_date).getTime()/1000),
              // alert_status: alert.alert_status,
              alert_text: alert.breach_description,
              alert_id: alert.alert_access_key
            };
            this.alertsView.push(
                {
                  idx: alert.breach_id, id: spyDwbCommerceContent._id, is_view: false, img_src: '/assets/spyandid/bottom_arrow.svg', alarm: breachAlart
                }
            );      
          };  
      }
      LogUtils.debug("breachs data => ", this.breaches);
      LogUtils.debug("alertsView data => ", this.alertsView);
      LogUtils.debug("alert data => ", this.alerts);
    }).then(() => {
      this.initDone = true;
    });
  }

  
  clearNotes() {
    this.notes = [];
    this.publicNotes = [];
    this.privateNotes = [];
    this.privateNoteExpanded = [];
    this.publicNoteExpanded = [];
  }

  setExistFlag(person: SearchedPerson) {
    if (person) {
      //////////////////////////
      // Personal Section
      this.existFlags.birthRecordsSubSection = !!person.dob;
      this.existFlags.deathRecordsSubSection = !!person.dateOfDeath;
      this.existFlags.marriageRecordsSubSection = person.marriages.length > 0;
      this.existFlags.divorceRecordsSubSection = person.divorces.length > 0;

      //////////////////////////
      // Social Network Section
      this.existFlags.socialPhotoSubSection = person.photos.length !== 0;
      this.existFlags.otherSocialNetworkSubSection = false;
      person.profiles.some((profile) => {
        if (!profile.photos) {
          this.existFlags.otherSocialNetworkSubSection = true;
          return true;
        }
      });

      //////////////////////////
      // Consumer Profile Section
      if (person.consumerProfile) {
        this.existFlags.consumerProfileSubSection = isObject(person.consumerProfile) && Object.keys(person.consumerProfile).length > 0;
        this.existFlags.householdSubSection = isObject(person.household) && Object.keys(person.household).length > 0;
        this.existFlags.financeSubSection = isObject(person.finance) && Object.keys(person.finance).length > 0;
      }

      //////////////////////////
      // Property Section
      if (person.properties) {
        this.existFlags.propertySubSection = person.properties.length > 0;
        this.existFlags.addressSubSection = person.addresses.length > 0;
      }

      //////////////////////////
      // Eviction Section
      if (person.evictions) {
        this.existFlags.evictionSubSection = person.evictions.length > 0;
      }

      //////////////////////////
      // Foreclosure Section
      if (person.foreclosures) {
        this.existFlags.foreclosureSubSection = person.foreclosures.length > 0;
      }

      //////////////////////////
      // Criminal Section
      if (person.criminalRecords) {
        this.existFlags.trafficViolationSubSectionLastIndex = 0;
        person.criminalRecords.forEach((criminalRecord, index) => {
          if (!!criminalRecord.category) {
            if (!criminalRecord.category.toUpperCase().startsWith('TRAFFIC')) {
              this.existFlags.criminalRecordsSubSection = true;
            } else {
              this.existFlags.trafficViolationSubSection = true;
              this.existFlags.trafficViolationSubSectionLastIndex = index;
            }
          }
        });
      }

      //////////////////////////
      // Court Section
      if (person.courts) {
        person.courts.forEach((court) => {
          if (court.type === "Bankruptcy") {
            this.existFlags.bankruptciesSubSection = true;
          } else if (court.type === "Lien") {
            this.existFlags.liensSubSection = true;
          } else if (court.type === "Judgment") {
            this.existFlags.judgmentsSubSection = true;
          }
        });
      }

      //////////////////////////
      // Business Section
      if (person.businesses) {
        person.businesses.forEach((business) => {
          if (business.sourceType !== "FBN") {
            this.existFlags.businessSubSection = true;
          } else {
            this.existFlags.fbnSubSection = true;
          }
        });
      }
      if (person.feins) {
        this.existFlags.feinSubSection = person.feins.length > 0;
      }
      if (person.domains) {
        this.existFlags.domainSubSection = person.domains.length > 0;
      }

      //////////////////////////
      // License Section
      if (person.licenses) {
        this.existFlags.professionalSubSection = person.licenses.length > 0;
      }

      //////////////////////////
      // Horoscope Section
      this.existFlags.horoscopeSubSection =
        person.tempClient &&
        person.tempClient.horoscope &&
        person.tempClient.horoscope.sign;
    }
  }

  initConfig(person: SearchedPerson, config: PersonDetailComponentConfig) {
    if (!config) {
      config = new PersonDetailComponentConfig();
    }
    Object.keys(config.tooltip).forEach((key) => {
      config.tooltip[key] = this.uxHelper.getUxcomp("tooltip." + key);
    });

    let override = this.uxHelper.getUxcomp("config");
    if (override && override.personDetailComponentConfig && objectUtils.isObject(override.personDetailComponentConfig)) {
      Object.keys(override.personDetailComponentConfig).forEach((key) => {
        config[key] = override.personDetailComponentConfig[key];
      });
    }

    if (!person?.addresses.find(address => !!address.neighbors)) {
      config.addressSubSectionNeighborhood = false;
    }

    return config;
  }

  setDerivedPerson(person: SearchedPerson) {
    let param = {
      type: "person",
      action: "derivedPerson",
      detail: person.extId,
    };
    this.serviceHelperService.trackingService.report(param).catch((e) => {
      LogUtils.error(e);
    });
    this.derivedPerson = person;
    this.initDerivedPersonConfig(person);
  }

  initDerivedPersonConfig(person: SearchedPerson) {
    this.derivedConfig = this.initConfig(person, null);
    this.derivedConfig.main = false;
    this.derivedConfig.lhs = false;
    this.derivedConfig.derivedPerson = false;
    this.derivedConfig.headline = false;
    this.derivedConfig.viewReport = this.config.viewReport;

    this.derivedConfig.socialNetworkSection = false;
    this.derivedConfig.consumerProfileSection = false;
    this.derivedConfig.householdSubSection = false;
    this.derivedConfig.financeSubSection = false;
    this.derivedConfig.evictionSection = false;
    this.derivedConfig.foreclosureSection = false;
    this.derivedConfig.criminalSection = false;
    this.derivedConfig.courtSection = false;
    this.derivedConfig.businessSection = false;
    this.derivedConfig.licenseSection = false;

    this.derivedConfig.associatesSubSection = false;
    this.derivedConfig.relativesSubSection = false;
    this.derivedConfig.birthRecordsSubSection = false;
    this.derivedConfig.deathRecordsSubSection = false;
    this.derivedConfig.marriageRecordsSubSection = false;
    this.derivedConfig.divorceRecordsSubSection = false;

    if (this.person?.providers.includes('IDI')) {
      this.derivedConfig.horoscopeSection = false;
    }

    this.derivedConfig.propertySubSection = false;
    this.derivedConfig.addressSubSectionNeighborhood = false;
  }

  onDestroy() {
    return super.onDestroy();
  }

  goToHash($event) {
    let hash = $event.target.value;
    behaviorHelper.goToHash(hash);
  }

  searchPerson(person: SearchedPerson) {
    this.serviceHelperService.peopleSearchService.setSearchPersonToSearch(
      this.serviceHelperService,
      person
    );
    // open new tab
    window.open(
      "/" + customClientPaths.memberNameSearchLoader);
    // redirectHelper.redirect(
    //   this.serviceHelperService,
    //   "/" + customClientPaths.memberNameSearchLoader
    // );
    return false;
  }

  getOsmMapTile(address) {
    if (address && address.latitude && address.longitude) {
      let lat = mapUtils.osmLat2tile(address.latitude, this.zoom);
      let lng = mapUtils.osmLong2tile(address.longitude, this.zoom);
      return (address.osmUrl = mapUtils.osmToUrl(lat, lng, this.zoom));
    }
  }

  getGoogleMapUrl(address) {
    if (address) {
      return this.googleMap + address.fullAddress;
    }
  }

  subscribe(flag) {
    const commerceStorage = this.serviceHelperService.commerceService.getStorage()

    // DWB Monitoring
    if(commerceStorage?.idProtectionFeatures?.idDarkWebMonitoring?.enabled && this.idProtectionEnabled) {
      this.monitorDwb(flag)
    }

    // People Search Subscribe
    this.commerceContent.setSubscribe(flag);
    return Promise.resolve().then(() => {
      if (this.commerceContent) {
        this.serviceHelperService.peopleSearchService
          .subscribe(this.commerceContent, flag)
          .then(() => {
            this.commerceContent.setSubscribe(flag);
          })
          .catch((e) => {
            this.toastr.error(e.rawResponse.message, "Error");
            this.commerceContent.setSubscribe(!flag);
          });
      }
    });
  }


  monitorDwb(flag) {
    if(!this.commerceContent.data?.spyDwbCommerceContentId || this.getEmails().length < 1) { return; }
    const commerceStorage = this.serviceHelperService.commerceService.getStorage()

    this.serviceHelperService.spinnerService.spin();
    this.serviceHelperService.commerceService.monitorDwb(
      this.commerceContent.data?.spyDwbCommerceContentId,
      this.commerceContent._id,
      commerceStorage?.idProtectionContentId,
      flag,
      this.getEmails(),
      this.person?.fName,
      this.person?.lName
    )
    .then(() => {
      this.serviceHelperService.spinnerService.unspin()
      //tracking enrollment/un-enrollment
      if(flag) {
        this.serviceHelperService.trackingService.report({
          page: BaseComponent.PAGE.detail,
          pageType: BaseComponent.PAGE_TYPE.member,
          type: "DWBEmailMonitoring",
          action: "enrollment",
          monitoredEmails: this.getEmails()
        });
      } else {
        this.serviceHelperService.trackingService.report({
          page: BaseComponent.PAGE.detail,
          pageType: BaseComponent.PAGE_TYPE.member,
          type: "DWBEmailMonitoring",
          action: "unenrollment",
        });
      }

     })
    .catch(() => this.serviceHelperService.spinnerService.unspin());
  }

  getEmails() {
    return this.person.emailAddresses?.map((item) => item.address) || []
  }

  handleDelete(note: Note) {
    if (this.authService.getUserId() !== note.ownerId) {
      $('#request-delete-note-modal').modal('show');
      return;
    }
    return this.noteService.deleteNote(note._id).then(() => {
      $("#success-delete-note-modal").modal('show');
      this.findNotes();
    }).catch((e: any) => {
      LogUtils.error("Handle Delete Error :::: ",e);
    })
  }

  convertNote(note: string) {
    if (note.length > 200) return note.slice(0, 50).concat(' ...');
    return note;
  }

  findNotes() {
    this.publicNotes = [];
    this.privateNotes = [];
    this.privateNoteExpanded = [];
    this.publicNoteExpanded = [];
    this.notes = [];
    this.noteService.findNote(this.person?.extId).then(notes => {
      notes.forEach(note => {
        if (note.privateFlag) this.privateNotes.push(note)
        else this.publicNotes.push(note)
      });
      this.publicNoteExpanded = new Array(this.publicNotes.length).fill(true);
      this.privateNoteExpanded = new Array(this.privateNotes.length).fill(true);
    }).catch((e: any) => {
      LogUtils.error("Find Notes Error :: ",e)
    })
  }

  handleReload() {
    this.onReload.emit();
  }
}

