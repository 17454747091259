import { AfterViewInit, Component, ElementRef, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDirective } from '../../../../clientCommon/directives/BaseDirective';
import { ServiceHelperService } from '../../../../clientCommon/services/serviceHelper.service';
import { AddressField, AddressUtils } from '../../../../common/utils/addressUtils';
import { LogUtils } from '../../../../common/utils/logUtils';
import { CommerceOrder } from '../../../../common/models/commerce/commerceOrder';
import { phoneUtils } from '../../../../common/utils/phoneUtils';
import { emailUtils } from '../../../../common/utils/emailUtils';
import { stringUtils } from '../../../../common/utils/stringUtils';
import { peopleSearchProductKeys } from '../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { SearchedPerson } from '../../../../common/custom/models/peopleSearch/searchedPerson';
import { customClientPaths } from '../../../../common/custom/customPathHelpers';
import { redirectHelper } from '../../../../clientCommon/helper/redirectHelper';
import { BaseComponent } from '../../../../clientCommon/components/BaseComponent';
import { User } from '../../../../common/models/user/user';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

class CustomProduct {
  public static ACTION = {
    redirect: 'redirect',
  };

  productType: string;
  name: string;
  action: string;
  value: string;
  forms: {
    [type: string]: any;
  }[] = [];

  public static CODE = {
    requestState: 'requestState',
  };
}

@Component({
  selector: 'app-member-people-search',
  templateUrl: 'memberPeopleSearch.component.html',
  styleUrls: ['./memberPeopleSearch.component.scss'],
})
export class MemberPeopleSearchComponent extends BaseDirective implements OnInit {
  addressField: AddressField = AddressUtils.getAddressField('US');
  allStateObj = [{ code: 'all', name: 'All States' }];
  addressFieldWithAllStateOption;
  addressFieldWithEmptyStateOption;

  peopleSearchProductKeys = peopleSearchProductKeys;
  productKeys = [peopleSearchProductKeys.nameSearch, peopleSearchProductKeys.phoneSearch];
  validProducts: { [key: string]: boolean } = {};
  productType;
  phoneUtils = phoneUtils;

  isFirstNameDisabled = false;
  isLastNameDisabled = false;
  isMiddleNameDisabled = false;
  isAgeDisabled = false;
  isCityDisabled = false;
  isStateDisabled = false;
  isPhoneDisabled = false;
  isEmailDisabled = false;

  @Input() defaultProductType: string;
  @Input() isFromHeader: boolean = false;
  @Input() defaultTab: string = 'name';
  @Input() isPhoneSearchAllowed: boolean = true;
  @Input() isNameSearchAllowed: boolean = true;

  productsFlag = false;
  error = false;
  lifeEventsStateErrorMessage: boolean = false;
  lifeEventsTypeErrorMessage: boolean = false;

  @ViewChild('cityInput') cityInput: ElementRef<HTMLInputElement>;

  customProducts: CustomProduct[] = [];
  selectedCustomProduct: CustomProduct;
  selectedCustomProductValues: any = {};
  customProductsKey = 'comp.member.peopleSearch.customProducts';
  configKey = 'comp.member.peopleSearch.searchBox';
  config: { defaultProductType?: string } = {};
  ageArr = [];
  activeForm = 1;
  isSubmit: boolean = false;
  isValidFlag: boolean = true;
  isValidAdvancedFlag: boolean = true;
  stringUtils = stringUtils;
  user: User;
  ageRange = [
    { name: '18 - 30 yrs old', value: { min: 18, max: 30 } },
    { name: '31 - 50 yrs old', value: { min: 31, max: 50 } },
    { name: '51 - 70 yrs old', value: { min: 51, max: 70 } },
    { name: '71 - 90 yrs old', value: { min: 71, max: 90 } },
    { name: '91 - 110+ yrs old', value: { min: 91, max: 150 } },
  ];
  lifeEvents = ['Marriage Records', 'Divorce Records', 'Death Records'];
  myState;

  constructor(serviceHelperService: ServiceHelperService, private router: Router, activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    // this.addressField.addAdministrativeAreaOption({all: "All States" });
    // this.addressField.sortAdministrativeAreaOptionsArray();
    this.addressFieldWithAllStateOption = [
      ...this.allStateObj,
      ...this.addressField.getAdministrativeAreaOptionsArray(),
    ];
    this.addressFieldWithEmptyStateOption = [
      ...[{ code: 'all', name: 'Select a State' }],
      ...this.addressField.getAdministrativeAreaOptionsArray(),
    ];

    this.myState = [...this.addressFieldWithAllStateOption];

    this.ageArr = Array(100)
      .fill(null)
      .map((_, index) => ({ code: index + 1, name: index + 1 }));

    // set current logged in user
    this.isUserLogged();

    return super
      .baseInit()
      .then(() => {
        this.config = this.findConfig();
        if (!this.defaultProductType) {
          this.defaultProductType = this.config.defaultProductType;
        }
        if (this.config.defaultProductType === peopleSearchProductKeys.nameSearch) {
          this.activeForm = 1;
        } else if (this.config.defaultProductType === peopleSearchProductKeys.phoneSearch) {
          this.activeForm = 2;
        } else if (this.config.defaultProductType === peopleSearchProductKeys.lifeEvents) {
          this.activeForm = 3;
        } else if (this.config.defaultProductType === peopleSearchProductKeys.inmateSearch) {
          this.activeForm = 4;
        }
        // default userinput state value setting
        if (!this.userInput.state) {
          this.userInput.state = 'all';
        }
      })
      .then(() => {
        return this.serviceHelperService.commerceService.findCommerceOrders();
      })
      .then((commerceOrders: CommerceOrder[]) => {
        this.productKeys.forEach((productKey) => {
          this.validProducts[productKey] = this.serviceHelperService.commerceService.hasProductInOrder(
            this.uxComposite,
            commerceOrders,
            [productKey]
          );
          if (this.validProducts[productKey]) {
            this.productsFlag = true;
          }
        });

        LogUtils.debug('memberPeopleSearch commerceOrders', commerceOrders, this.validProducts);
      })
      .then(() => {
        this.customProducts = this.findCustomProducts();
        this.customProducts.forEach((customProduct) => {
          this.validProducts[customProduct.productType] = true;
        });
      })
      .then(() => {
        if (this.validProducts[this.defaultProductType]) {
          this.productType = this.defaultProductType;
        } else {
          this.productKeys.some((productKey) => {
            if (this.validProducts[productKey]) {
              this.productType = productKey;
              if (this.defaultTab === 'name') {
                this.activeForm = 1;
                this.switchSearchMode(peopleSearchProductKeys.nameSearch, this.activeForm);
                this.productType = peopleSearchProductKeys.nameSearch;
              }
              if (this.defaultTab === 'phone') {
                this.activeForm = 2;
                this.switchSearchMode(peopleSearchProductKeys.phoneSearch, this.activeForm);
                this.productType = peopleSearchProductKeys.phoneSearch;
              }
              if (this.defaultTab === 'lifeEvents') {
                this.activeForm = 3;
                this.switchSearchMode(peopleSearchProductKeys.lifeEvents, this.activeForm);
                this.productType = peopleSearchProductKeys.lifeEvents;
              }
              if (this.defaultTab === 'inmate') {
                this.activeForm = 4;
                this.switchSearchMode(peopleSearchProductKeys.inmateSearch, this.activeForm);
                this.productType = peopleSearchProductKeys.inmateSearch;
              }
              return true;
            }
          });
        }
      })
      .then(() => {
        if (!this.productType) {
          this.productType = peopleSearchProductKeys.nameSearch;
        }
        this.selectedCustomProduct = this.findCustomProduct(this.productType);
      })
      .then(() => {
        // set search to myself
        if (this.serviceHelperService.searchMyself) {
          this.switchSearchMode(this.peopleSearchProductKeys.nameSearch, 3);
        }
      })
      .catch((e) => {
        LogUtils.error(e);
      })
      .then(() => {
        this.initDone = true;
      });
  }

  findConfig() {
    let config = this.uxComposite.get(this.configKey);
    if (!config) {
      config = {};
    }

    return config;
  }

  update() {
    this.serviceHelperService.userInputService.storeUserInput();
  }

  findCustomProducts(): CustomProduct[] {
    let customProducts: CustomProduct[] = this.uxComposite.get(this.customProductsKey);

    if (!customProducts) {
      customProducts = [];
    }

    return customProducts;
  }

  findCustomProduct(productType) {
    let foundCustomProduct: CustomProduct = null;
    this.customProducts.some((customProduct) => {
      if (customProduct.productType === productType) {
        foundCustomProduct = customProduct;
        return true;
      }
    });

    if (foundCustomProduct) {
      if (!this.selectedCustomProductValues[productType]) {
        this.selectedCustomProductValues[productType] = {};
      }

      if (foundCustomProduct.forms) {
        foundCustomProduct.forms.forEach((form) => {
          if (form.name && form.default && this.selectedCustomProductValues[productType][form.name] === undefined) {
            let value = form.default;
            if (value === CustomProduct.CODE.requestState) {
              value = this.uxComposite.requestState;
            }
            this.selectedCustomProductValues[productType][form.name] = value;
            let option = this.findOptionByKey(form.options, value);
            if (option) {
              foundCustomProduct.value = option.url;
            }
          }
        });
      }
    }

    return foundCustomProduct;
  }

  findOptionByKey(options, event) {
    const key = event.target.value;
    let chosenOption;
    if (options) {
      options.some((option) => {
        if (option.key === key) {
          chosenOption = option;
          return true;
        }
      });
    }

    return chosenOption;
  }

  // set current logged in user
  isUserLogged() {
    this.user = this.serviceHelperService.authenticationService.getUser();
  }

  isValid() {
    let flag = false;
    if (this.activeForm !== 5) {
      if (this.productType === peopleSearchProductKeys.nameSearch) {
        if (this.userInput.fname && this.userInput.lname && this.userInput.state) {
          flag = true;
        }
      } else if (this.productType === peopleSearchProductKeys.phoneSearch) {
        if (this.phoneUtils.validDigits(this.userInput.phone)) {
          flag = true;
        }
      } else if (this.activeForm === 4) {
        flag = this.userInput.state !== '';
      } else {
        flag = true;
      }
    }

    if (this.activeForm === 5) {
      // phone
      if (this.userInput.phone && this.userInput.phone !== '') {
        if (this.phoneUtils.validDigits(this.userInput.phone)) {
          flag = true;
        }else {
          flag = false;
        }
      } else if(this.userInput.email && this.userInput.email !== '') {
        if (emailUtils.validateEmail(this.userInput.email)) {
          flag = true;
        }else {
          flag = false;
        }
      } else {
        if (this.userInput.fname && this.userInput.lname) {
          flag = true;
        }else {
          flag = false;
        }
        this.isValidFlag = flag;
      }
      this.isValidAdvancedFlag = flag;
      return flag;
    }else {   
      this.isValidFlag = flag;
      return flag;
    }
  }

  submit() {    
    this.userInput = this.serviceHelperService.userInputService.getUserInput();

    if (this.activeForm === 5) {
      // phone
      if (this.userInput.phone && this.userInput.phone !== '') {
        this.productType = peopleSearchProductKeys.phoneSearch;
        this.userInput.state = undefined;
      } else if(this.userInput.email && this.userInput.email !== '') {
        this.productType = peopleSearchProductKeys.nameSearch;
        this.userInput.state = undefined;
      }
    }

    LogUtils.debug('submit', this.activeForm, this.userInput);
    this.serviceHelperService.userInputService.storeUserInput();

    if (this.activeForm == 3) {
      this.lifeEventsStateErrorMessage = typeof this.userInput.state !== 'string';
      this.lifeEventsTypeErrorMessage = !this.userInput.lifeEvents;
      if (this.lifeEventsStateErrorMessage || this.lifeEventsTypeErrorMessage) {
        return false;
      }
    }
    this.isSubmit = true;
    if (this.isValid() == false) return;
    this.isSubmit = false;

    let url = null;
    this.serviceHelperService.peopleSearchService.clear();
    let mode = '';
    switch (this.activeForm) {
      case 1:
        mode = peopleSearchProductKeys.nameSearch;
        break;
      case 2:
        mode = peopleSearchProductKeys.phoneSearch;
        break;
      case 3:
        mode = peopleSearchProductKeys.lifeEvents;
        break;
      case 4:
        mode = peopleSearchProductKeys.inmateSearch;
        break;
      case 5:
        mode = 'advanced';
        break;
    }



    this.serviceHelperService.trackingService.report({
      mode,
      page: BaseComponent.PAGE.landing,
      pageType: BaseComponent.PAGE_TYPE.member,
      action: 'submit',
    });

    if (this.userInput.fname === '_EXTID_') {
      let person = new SearchedPerson();
      person.lName = 'NA';
      person.fName = 'NA';
      person.extId = this.userInput.lname;
      this.serviceHelperService.peopleSearchService.setSearchPersonToSearch(this.serviceHelperService, person);
      url = '/' + customClientPaths.memberNameSearchLoader;
    } else if (this.productType === peopleSearchProductKeys.nameSearch) {
      if(this.userInput.email && this.userInput.email !== '' && this.activeForm === 5) {
        this.userInput.fname = undefined;
        this.userInput.mname = undefined;
        this.userInput.lname = undefined;        
        this.userInput.phone = undefined;
        this.userInput.lifeEvents = undefined;
        this.userInput.city = undefined;
        this.userInput.state = undefined;
      }else {
        this.userInput.phone = undefined;
        this.userInput.lifeEvents = undefined;
        this.userInput.email = undefined;
        //this.userInput.city = undefined;
      }
      url = '/' + customClientPaths.memberNameSearchLoader;
    } else if (this.productType === peopleSearchProductKeys.phoneSearch) {
      this.userInput.fname = undefined;
      this.userInput.mname = undefined;
      this.userInput.email = undefined;
      this.userInput.lname = undefined;
      this.userInput.state = undefined;
      this.userInput.city = undefined;
      this.userInput.lifeEvents = undefined;
      url = '/' + customClientPaths.memberPhoneSearchLoader;
    } else if (this.productType === peopleSearchProductKeys.lifeEvents) {
      this.userInput.fname = undefined;
      this.userInput.mname = undefined;
      this.userInput.lname = undefined;
      this.userInput.email = undefined;
      this.userInput.city = undefined;
      this.userInput.phone = undefined;
      url = '/' + customClientPaths.lifeEvents;
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.lifeEvents,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: 'LifeEvents',
        action: this.lifeEvents,
      });
    } else if (
      this.productType === peopleSearchProductKeys.inmateSearch &&
      this.activeForm == 4 &&
      this.userInput.state != undefined &&
      this.userInput.state != '' &&
      this.userInput.state != 'all'
    ) {
      this.router.navigateByUrl(this.customClientPaths.memberInmateState + '/' + this.userInput.state.toLowerCase());
    } else {
      let customProduct = this.findCustomProduct(this.productType);
      if (customProduct) {
        if (customProduct.action === CustomProduct.ACTION.redirect) {
          url = customProduct.value;
        }
      }
    }
    if (url) {
      this.serviceHelperService.userInputService.storeUserInput();
      return redirectHelper.redirect(this.serviceHelperService, url);
    } else {
      this.error = true;
    }
  }

  switchSearchMode(mode: string, activeForm: number): void {
    this.activeForm = activeForm;
    this.productType = mode;
    this.selectedCustomProduct = this.findCustomProduct(mode);
    if (activeForm === 4) {
      this.userInput.state = ''; // null;
      this.serviceHelperService.searchMyself = false;
    } else if(activeForm === 5) {
      if (this.userInput.phone && this.userInput.phone !== '') {
        this.isEmailDisabled = true;
        this.otherDisableSet();
      } else if(this.userInput.email && this.userInput.email !== '') {
        this.isPhoneDisabled = true;
        this.otherDisableSet();
      } else {
        if (( this.userInput?.fname && this.userInput?.fname !== '')
         || (this.userInput?.lname && this.userInput?.lname !== '')
         || (this.userInput?.mname && this.userInput?.mname !== '')
        // || (this.userInput?.age)
         || (this.userInput.city && this.userInput?.city !== '')) {
         //|| (this.userInput.state && this.userInput?.state !== '')) {
          this.isEmailDisabled = true;
          this.isPhoneDisabled = true;
        } else {
          this.allEnableSet();
        }
      }

    } else {
      this.serviceHelperService.searchMyself = false;
    }
    if (!this.userInput.state) {
      this.userInput.state = 'all';
    }
  }

  focusOnCity() {
    this.cityInput.nativeElement.focus();
  }

  handleAgeRangeChange(event) {
    const { value } = event;
    this.userInput.minAge = value.min;
    this.userInput.maxAge = value.max;
  }

  calcDefaultRange() {
    const userInput = this.serviceHelperService.storageService.get('userInput');
    if (JSON.stringify(userInput)['minAge']) {
      return (+JSON.stringify(userInput)['minAge'] - 1) / 10;
    }
    return null;
  }

  formatInput(event, type) {
    if (type === 'fname') {
      event.target.value = this.userInput.fname = stringUtils.formatName(this.userInput.fname, event);
    } else if (type === 'lname') {
      event.target.value = this.userInput.lname = stringUtils.formatName(this.userInput.lname, event);
    } else if (type === 'mname') {
      event.target.value = this.userInput.mname = stringUtils.formatName(this.userInput.mname, event);
    } else if (type === 'phone') {
      event.target.value = this.userInput.phone = phoneUtils.formatPhoneNumber(event.target.value);
    } else if (type === 'city') {
      event.target.value = this.userInput.city = stringUtils.formatName(this.userInput.city, event);
    }
  }

  checkEvent(event, type) {
    if (type === 'phone') {
      if (event.target.value !== '') {
        this.isEmailDisabled = true;
        this.otherDisableSet();
      } else {
        // all enable
        this.allEnableSet();
      }
    } else if(type === 'email') {
      if (event.target.value !== '') {
        this.isPhoneDisabled = true;
        this.otherDisableSet();
      } else {
        // all enable
        this.allEnableSet();
      }
    } else {
      if (event.target.value !== '') {
        this.isEmailDisabled = true;
        this.isPhoneDisabled = true;
      }else {
        this.allEnableSet();
      }
    }
  }
  otherDisableSet() {
    this.isFirstNameDisabled = true;
    this.isLastNameDisabled = true;
    this.isMiddleNameDisabled = true;
    this.isAgeDisabled = true;
    this.isCityDisabled = true;
    this.isStateDisabled = true;
  }
  allEnableSet() {
    this.isFirstNameDisabled = false;
    this.isLastNameDisabled = false;
    this.isMiddleNameDisabled = false;
    this.isAgeDisabled = false;
    this.isCityDisabled = false;
    this.isStateDisabled = false;
    this.isPhoneDisabled = false;
    this.isEmailDisabled = false;
    this.isValidAdvancedFlag = true;
    this.isValidFlag = true;
  }

  onLifeEventChange() {
    // this.userInput.state = 'All States';
    this.myState = [...this.addressFieldWithAllStateOption];
    this.myState = this.myState.splice(1, 51);
    if (this.userInput?.state?.length !== 2) {
      this.userInput.state = this.myState.splice(1, 1);
    }
  }
  
  // filterInput(event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   inputElement.value = inputElement.value.replace(/[^a-zA-Z0-9_\-\s]/g, '');
  //   event.target.value = inputElement.value;
  // }

}

@NgModule({
  declarations: [MemberPeopleSearchComponent],
  imports: [CommonModule, FormsModule, NgSelectModule],
  exports: [MemberPeopleSearchComponent],
})
export class MemberPeopleSearchComponentModule {}
