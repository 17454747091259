import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-mini-report-note',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class MiniReportNotesComponent implements OnInit {
  @Input() base: any;
  @Input() isPdfPrintView = false;

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  declarations: [MiniReportNotesComponent],
  imports: [CommonModule],
  exports: [MiniReportNotesComponent],
})
export class MiniReportNotesComponentModule {}
