import { 
  Component, 
  Input, 
  NgModule, 
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { customClientPaths } from '../../../../../../common/custom/customPathHelpers';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperModule } from '../../../../../../clientCommon/directives/SwiperDirective';

@Component({
  selector: 'app-additional-resources',
  templateUrl: './additional-resources.component.html',
  styleUrls: ['./additional-resources.component.scss'],
})
export class AdditionalResourcesComponent {
  @Input() summary: String = '';
  @Input() resourceArr: Array<any> = [];
  
  redirectPage = customClientPaths.memberNameSearchRedirectWarning;
  
  public config: SwiperOptions = {
    spaceBetween: 16,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      767: {
        slidesPerView: 3,
      },
    },
  };

  thirdPartyAlertUrl: string = '';

  goToLink() {
    window.open(this.thirdPartyAlertUrl, '_blank');
    this.thirdPartyAlertUrl = '';
  }

  saveLink(url) {
    window.open(`/${this.redirectPage}?thirdpartyURL=${url}`, '_blank');
  }
}

@NgModule({
  declarations: [AdditionalResourcesComponent],
  imports: [CommonModule, SwiperModule],
  exports: [AdditionalResourcesComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AdditionalResourcesComponentModule {}
