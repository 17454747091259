import { MemberMenuComponent } from './memberMenu.component';
import { MHP_COMPONENTS_DECLARATIONS } from './mhpFlow';
import { MemberHeaderComponentModule } from './memberHeader.component';
import { MemberDisclaimerComponentModule } from './memberDisclaimer.component';
import { MemberClosureComponentModule } from './memberClosure.component';
import { MemberFooterComponentModule } from './memberFooter.component';
import { MemberPeopleSearchComponentModule } from './peopleSearch';
import { MemberKeepSearchComponentModule } from './peopleSearch/memberKeepSearch.component';

export const MEMBER_COMPONENTS_DECLARATIONS = [
  MemberMenuComponent,
  ...MHP_COMPONENTS_DECLARATIONS,
];

export const MEMBER_COMPONENT_IMPORTS = [
  MemberHeaderComponentModule,
  MemberFooterComponentModule,
  MemberDisclaimerComponentModule,
  MemberClosureComponentModule,
  MemberPeopleSearchComponentModule,
  MemberKeepSearchComponentModule,
];
