import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-mini-report-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class MiniReportHeader implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() banner: string;
  @Input() keepVertical: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [MiniReportHeader],
  imports: [CommonModule],
  exports: [MiniReportHeader],
})
export class MiniReportHeaderModule {}
