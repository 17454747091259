// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-criminal__cards {
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
}
.mini-criminal__cards:before {
  display: block;
}
.mini-criminal__cards--single {
  margin-bottom: 30px;
}

.__title {
  line-height: 32px;
  font-size: 32px;
}
@media (max-width: 576) {
  .__title {
    font-size: 24px;
  }
}
@media (max-width: 991.98px) {
  .__title {
    font-size: 24px;
  }
}

.__sub_title {
  line-height: 18px;
  font-size: 18px;
}
@media (max-width: 576) {
  .__sub_title {
    font-size: 12px;
  }
}
@media (max-width: 991.98px) {
  .__sub_title {
    font-size: 12px;
  }
}

.mb-32 {
  margin-bottom: 32px;
}

.__criminal-header {
  padding-top: 4em;
  padding-bottom: 4em;
}`, "",{"version":3,"sources":["webpack://./src/app/member/peopleSearch/nameSearch/detail/miniCriminalRecords/miniCriminalRecords.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAGI;EACE,mBAAA;AADN;;AAMA;EAEE,iBAAA;EASA,eAAA;AAZF;AAIE;EAHF;IAII,eAAA;EADF;AACF;AAGE;EAPF;IAQI,eAAA;EAAF;AACF;;AAKA;EAEE,iBAAA;EASA,eAAA;AAXF;AAGE;EAHF;IAII,eAAA;EAAF;AACF;AAEE;EAPF;IAQI,eAAA;EACF;AACF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,gBAAA;EACA,mBAAA;AADF","sourcesContent":[".mini-criminal {\n  &__cards {\n    margin-top: 30px;\n    display: flex;\n    flex-flow: row wrap;\n\n    &:before {\n      display: block;\n    }\n\n    &--single {\n      margin-bottom: 30px;\n    }\n  }\n}\n\n.__title {\n\n  line-height: 32px;\n  @media (max-width: 576) { \n    font-size: 24px;\n  }\n\n  @media (max-width: 991.98px) { \n    font-size: 24px;\n  }\n\n  font-size: 32px;\n}\n\n.__sub_title {\n\n  line-height: 18px;\n  @media (max-width: 576) { \n    font-size: 12px;\n  }\n\n  @media (max-width: 991.98px) { \n    font-size: 12px;\n  }\n\n  font-size: 18px;\n}\n\n.mb-32 {\n  margin-bottom: 32px;\n}\n\n.__criminal-header {\n  padding-top: 4em;\n  padding-bottom: 4em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
