import {SalesClosureComponent} from "./salesClosure.component";
import {SalesDisclaimerComponent} from "./salesDisclaimer.component";
import {SalesFooterComponent} from "./salesFooter.component";
import {CreditCardLightboxComponent} from "./creditCardLightbox.component";
import {SalesHeaderComponent} from "./salesHeader.component";
import { ThreeDSComponent } from "./threeDS.component";

// Sales Components path declatation used will be used in "client/src/app/sales/peopleSearch/index.ts"
export const SALES_COMPONENTS_DECLARATIONS = [
  SalesClosureComponent,
  SalesDisclaimerComponent,
  SalesFooterComponent,
  SalesHeaderComponent,
  CreditCardLightboxComponent,
  ThreeDSComponent,
];

