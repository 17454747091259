import { UX_2_0_THEME_DECLARATIONS } from './ux.2.0';
import { UX_3_0_THEME_DECLARATIONS } from './ux.3.0';
import { UX_4_0_THEME_DECLARATIONS } from './ux.4.0';
import { UX_5_2_THEME_DECLARATIONS } from './2234200bd3cc36ba97274ecbdc6c6a47';
import { LEGACY_THEME_DECLARATIONS } from './legacy';
// import { UX_5_2_THEME_IMPORTS } from './2234200bd3cc36ba97274ecbdc6c6a47';

export const THEMES_DECLARATIONS = [
  ...UX_2_0_THEME_DECLARATIONS,
  ...UX_3_0_THEME_DECLARATIONS,
  ...UX_4_0_THEME_DECLARATIONS,
  ...UX_5_2_THEME_DECLARATIONS,
  ...LEGACY_THEME_DECLARATIONS,
];

//TODO: minsoub
// export const THEMES_IMPORTS = [...UX_5_2_THEME_DECLARATIONS];
