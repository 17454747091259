import { ViewChild, ViewContainerRef, } from '@angular/core';
import { DatePipe } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { isBoolean, isString } from "util";

import { BaseComponent } from "../../../clientCommon/components/BaseComponent";
import { CreditCardInputHelper } from "../../../clientCommon/helper/creditCardInputHelper";
import { creditCardUtils } from "../../../common/utils/creditCardUtils";
import { clientPaths } from "../../../common/helpers/pathHelpers";
import { ResponseEvent } from "../../../common/event/responseEvent";
import { LogUtils } from "../../../common/utils/logUtils";
import { UxComposite } from "../../../common/models/ux/uxComposite";
import { emailUtils } from "../../../common/utils/emailUtils";
import { Commerce3ds } from "../../../common/models/commerce/commerce3ds";
import { timeUtils } from "../../../common/utils/timeUtils";
import { CommerceContent } from "../../../common/models/commerce/commerceContent";
import { redirectHelper } from "../../../clientCommon/helper/redirectHelper";
import { behaviorHelper } from "../../../clientCommon/helper/behaviorHelper";
import { stringUtils } from "../../../common/utils/stringUtils";
import { SearchedPerson } from "../../../common/custom/models/peopleSearch/searchedPerson";
import { peopleSearchProductKeys } from "../../../common/custom/peopleSearch/peopleSearchProductKeys";
import { idProtectionProductKeys } from "../../../common/custom/idProtection/idProtectionKeys";
import { ServiceHelperService } from "../../../clientCommon/services/serviceHelper.service";
import { PeopleSearchContentHelper } from "../../../common/custom/peopleSearch/peopleSearchHelper";
import { CommerceOrder } from "../../../common/models/commerce/commerceOrder";
import { commerceUtils } from "../../../common/utils/commerceUtils";
import { phoneUtils } from "../../../common/utils/phoneUtils";
import { customServerPaths } from "../../../common/custom/customPathHelpers";
import { SequenceOptions } from "../../../common/models/commerce/commercePrice";
import { AddressField, AddressUtils } from "../../../common/utils/addressUtils";
import { BehaviorSubject } from 'rxjs';

declare var $: any;
declare var window: any;

/**
 * SearchResultPeopleSearchSales will be further extended by signup.component.ts
 * @extends BaseComponent to get theme and uxComp template etc. needed from uxHelper
 */
export abstract class SignUpPeopleSearch extends BaseComponent {

  /**
   * All properties definations that will be required by layout SUP components
   */
  public pillStepSubject = new BehaviorSubject<any>({})

  suggestedEmail: string;
  pillStep = -1;
  
  steps = {
    "emailAddress": "emailAddress", "email": "email",
    "billing": "billing",
  };

  creditCardInputHelper = new CreditCardInputHelper();
  oldCreditCardInputHelper = new CreditCardInputHelper();

  contentProductId: string;
  errorReason: string;
  processing: boolean;

  mpi3dsFlag: boolean;
  mpi3dsEnabled: boolean;
  mpi3dsGracePeriod: number;
  mpi3dsHidden: boolean;
  mpi3dsMerchantId: string;
  stringUtils = stringUtils;

  content; // For People Search

  commerceContent: CommerceContent;
  selectedPerson: SearchedPerson;

  fakeProgressors: any;
  maxProgressorExecutionCount = 2;
  currentProgressorExecutionCount = 0;
  currentStep;
  feedBackFlags: Record<string, unknown> = {};

  checkOptinRequired = true;
  phoneNumberRequired = true;
  phoneNumberOn = true;
  checkTerm = true;
  checkFcra = true;
  validEmail = false;
  failureFlag = false;
  associateSummaryLimit = 3;
  relativeSummaryLimit = 3;
  testimonials;
  reasonUxcompKey = "";
  commerceOrder: CommerceOrder = undefined;
  discountBoxUxcompKeySuffix = 'discount.box';
  phoneUtils = phoneUtils;
  smsEnabled = false;
  smsSubscribed = false;
  phoneNumberUsed: string[] = [];
  isProcessing = false;
  emailVerificationCheck: boolean = true;
  isThinMatchEnable: boolean;
  userInputFullName: string;
  isRemarketingEnable: boolean;
  addressFields: AddressField = AddressUtils.getAddressField("US")
  userInputState: string;
  lpCounty: string = '';

  PMAX_PROVIDER_TYPES = {
    customerEnrollment: "PrivacyMaxxCustomerEnrollment"
  }

  get offerService() {
    return this.serviceHelperService.commerceOfferService;
  }

  get offerRulesKey() {
    return this.offerService.defaultRulesKey;
  }

  get mainOfferKey() {
    return this.offerService.mainOfferKey;
  }

  get structuredCommerceOfferIds() {
    return this.offerService.structuredCommerceOfferIds;
  }

  get structuredCommerceOffers() {
    return this.offerService.structuredCommerceOffers;
  }

  /**
 *
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet.
 *
 */
  constructor(
    serviceHelperService?: ServiceHelperService,
    activatedRoute?: ActivatedRoute,
    public datePipe?: DatePipe,
  ) {
    super(serviceHelperService, activatedRoute);
    this.pageType = BaseComponent.PAGE_TYPE.sales;
    this.page = BaseComponent.PAGE.signup;
    this.pageCategory = ""; // Must be set on derived class.
    this.fakeProgressors = this.serviceHelperService.progressorService.fakeProgressors; // intitialize the fake progressor.
    this.currentStep = this.steps.email; // setup email as the initial step.
  }

  abstract getChildObject();


  onInit() {
    // namespace bindings
    window.my = window.my || {};
    window.my.namespace = window.my.namespace || {};
    window.my.namespace.publicFunc = this.publicFunc.bind(this);

    // Initializing BaseComponent
    return this.baseInit().then(() => {
      return this.init();
    }).then(() => {
      /**
       * defining all the initial credit card properties.
       */
      this.updateThinMatch();

      this.lpCounty = this.uxComposite.get('code.lp_county');

      this.activatedRoute.queryParams.subscribe((params) => {
        if (params?.refer_fName || params?.refer_lName) {
          this.userInputFullName =
            params.refer_fName + " " + params.refer_lName;
        }
      });
      this.isRemarketingEnable = this.uxComposite.getUxcomp('comp.sales.name-search.signup.remarketing.enabled');
      this.creditCardInputHelper.init();
      this.creditCardInputHelper.setStreetRuleUpdater(this.uxHelper);

      if (this.creditCardInputHelper.showStreetInput) {
        this.creditCardInputHelper.billingAddress.bogusFields.street1 = false; 
      }

      // If fullname is not provided along with credit card info, assign fname & lname from sup info to the fullname of the creditcard info
      this.setInfo();
      creditCardUtils.setDate(new Date(this.uxComposite.timestamp));


      // formatting for cvv and cc number
      this.creditCardInputHelper.processCCNumber(this.creditCardInputHelper.ccNumber, this.serviceHelperService);
      this.creditCardInputHelper.ccCvv = creditCardUtils.formatCvv(this.creditCardInputHelper.cardType, this.creditCardInputHelper.ccCvv);

      /**
       * get commerce content based on uxComposite, set the selectedPerson by getting it based on commerceContent
       * set the progressor and its progress if found in commerceContent
       */
      return this.serviceHelperService.peopleSearchService.getCommerceContent(this.uxHelper.uxComposite).then((commerceContent: CommerceContent) => {
        this.commerceContent = commerceContent;
        LogUtils.debug("signUpPeopleSearchSales CommerceContent => ", this.commerceContent);
        this.selectedPerson = this.serviceHelperService.peopleSearchService.findSelectedPerson(this.commerceContent);
        LogUtils.debug("SelectedPerson", this.selectedPerson);
        if (this.commerceContent.progressors && this.commerceContent.progressors.length) {
          this.serviceHelperService.progressorService.setProgressor(this.commerceContent.progressors[0]);
          this.serviceHelperService.progressorService.setCurrentProgress(timeUtils.getTimestamp());

          this.executeProgress().then(() => {
          });
        }
      }).catch((e) => { // log errors
        if (e === false) {
          LogUtils.debug(e);
        } else {
          LogUtils.error(e);
        }
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      /**
       * set uxComposite's codes for offers,selected person & offer total amount
       * and stop the spinner
       */
      this.setCodeToUxComposite();
      this.serviceHelperService.spinnerService.unspin();
    });
  }

  getPillStepAsObservable() {
    this.pillStepSubject.next(this.pillStep);
    return this.pillStepSubject.asObservable();
  }

  setPillStep(step) {
    this.pillStep = step;
    this.pillStepSubject.next(this.pillStep);
  }

  // track onBlur event on input
  onBlur($event) {
    this.trackEvent($event);
  }

  setCodeToUxComposite(uxComposite?: UxComposite) {
    if (!uxComposite) {
      uxComposite = this.uxHelper.uxComposite;
    }

    uxComposite.setCode("selectedPersonDetail", this.selectedPerson);
  }

  // nulify the window namespace as well call the BaseComponent onDestroy
  onDestroy() {

    window.my.namespace.publicFunc = null;
    return super.onDestroy();
  }


  // this call privateFunc(), using ngZone.run() to run it inside angular zone
  publicFunc() {
    this.serviceHelperService.ngZone.run(() => this.privateFunc());
  }

  // this FN set's mpi3dsFlag as true, its called by publicFunc() with ngZone.run() to run it inside angular zone
  privateFunc() {
    this.mpi3dsFlag = true;
  }

  /**
   * initialization function, will:
   *  -> set a unique hash, user input & info
   *  -> set's all the mpi 3ds ux_comps
   *  -> set the ux_comps for optin, phone_number, testimonial & sms api enable/disable boolean
   *  -> Finally, after the commerce offers initialization via initOffer() function it sets the current theme variablw via uxComposite
   */
  init(): Promise<any> {
    this.hash = this.serviceHelperService.commerceService.createHash();
    this.userInput = this.serviceHelperService.userInputService.getUserInput();


    this.userInfo = this.serviceHelperService.userInfoService.getUserInfo();
    if (this.userInfo.phone === undefined) {
      this.userInfo.phone = '';
    }
    this.processing = false;

    this.mpi3dsFlag = false;
    this.mpi3dsEnabled = !!this.uxHelper.getValue("comp.billing.mpi3ds.enabled");
    this.mpi3dsGracePeriod = this.uxHelper.getValue("comp.billing.mpi3ds.graceperiod");
    this.mpi3dsHidden = !!this.uxHelper.getValue("comp.billing.mpi3ds.hidden");
    this.mpi3dsMerchantId = this.uxHelper.getValue("comp.billing.mpi3ds.merchantId");

    this.checkOptinRequired = this.uxHelper.getUxcomp("email.optin.required");
    this.phoneNumberOn = this.uxHelper.getUxcomp("phone");
    this.phoneNumberRequired = this.phoneNumberOn && this.uxHelper.getUxcomp("phone.required");
    this.smsEnabled = this.phoneNumberOn && this.uxHelper.getUxcomp("phone");
    if (!isBoolean(this.userInfo.optin)) {
      this.userInfo.optin = true;
    }

    this.testimonials = this.uxHelper.getUxcomp("testimonials");
    // after the commerce offers initialization, set the current theme
    return this.initOffers().then(() => {
      this.theme = this.uxHelper.getTheme();
    });
  }

  initOffers() {
    this.isProcessing = true;
    return this.offerService.initOffers(this.uxComposite, {
      offerRulesKey: this.uxHelper.getUxcompKey(this.offerRulesKey)
    }).then(() => {
      // get content productId from commerceOffer depends on if the product key is for nameSearch or phoneSearch
      if (!this.structuredCommerceOffers[this.mainOfferKey] || this.structuredCommerceOffers[this.mainOfferKey].length === 0) {
        //return Promise.reject("No offer found");
        return false;
      } else if (this.getProductKeyByPageCategory() === peopleSearchProductKeys.nameSearch) {
        this.contentProductId = this.serviceHelperService.peopleSearchService.findPeopleSearchProductIdFromCommerceOffers(this.getProductKeyByPageCategory(), this.structuredCommerceOffers[this.mainOfferKey]);
      } else if (this.getProductKeyByPageCategory() === peopleSearchProductKeys.phoneSearch) {
        this.contentProductId = this.serviceHelperService.peopleSearchService.findPeopleSearchProductIdFromCommerceOffers(this.getProductKeyByPageCategory(), this.structuredCommerceOffers[this.mainOfferKey]);
      }
    }).then(() => { // set uxComposite's codes for offers,selected person & offer total amount
      this.setCodeToUxComposite();
      this.isProcessing = false;
    }).then(() => { // load Paay 3ds script

    }).catch((e) => {
      this.isProcessing = false;
      LogUtils.error(e);
    });
  }

  /**
   * @functionOverview get uxlId and update uxComposite based on it and call current component init()
   * @param event is an event object to get event info of the clicked html element
   * @returns exit modal text
   */
  onBeforeUnload(event) {
    if (this.uxHelper.hasUxComposite()) {
      let text = this.uxHelper.getUxcomp("exit.modal.text");
      if (text) {
        event.returnValue = text;
        setTimeout(() => {
          let uxlId = this.uxHelper.getUxcomp("exit.modal.stay.cascade");
          try {
            this.serviceHelperService.spinnerService.spin();
            this.serviceHelperService.uxcService.cascadeUxComposite(uxlId).then((uxComposite: UxComposite) => {
              this.updateUxComposite(uxComposite);
              window.scrollTo(0, 0);
              return this.init();
            }).catch((e) => {
              LogUtils.error(e);
            }).then(() => {
              this.serviceHelperService.spinnerService.unspin();
            });
          } catch (e) {
            this.serviceHelperService.spinnerService.unspin();
            LogUtils.error(e);
          }
        }, 100);
        return text;
      }
    }
  }

  /**
   * If fullname is not provided along with credit card info,
   *  take and set fname and lname from sup userinfo and assign them to the creditcard full name property
   */
  setInfo() {
    if (!this.creditCardInputHelper.fullName) {
      if (this.userInfo.fname) {
        this.creditCardInputHelper.fullName = this.userInfo.fname;
      }
      if (this.userInfo.lname) {
        this.creditCardInputHelper.fullName += " " + this.userInfo.lname;
      }
      if (this.creditCardInputHelper.fullName) {
        this.creditCardInputHelper.fullName.trim();
      }
    }

  }

  /**
   * @functionOverview validate required fields for the step & call smsSubscribe API
   * @param step sup step can be 'emailAddess' or 'billing'
   * @returns flag = true, if all the validations for the step are passed
   */
  isValidStep(step) {
    let productKey = this.getProductKeyByPageCategory();
    let validations: any = {};
    let flag = true;

    // start the spinner, if current step is billing
    if (!(this.steps.billing === step)) {
      this.serviceHelperService.spinnerService.spin();
    }

    // reject or resolve promise based on email address validation with regex test
    return Promise.resolve().then(() => {
      return this.isValidEmail().then((email) => {
        if (emailUtils.validateEmail(this.userInfo.email)) {
          this.userInfo.email = email
        }

        validations[this.steps.emailAddress] = true;
      }).catch((e) => {


        validations[this.steps.emailAddress] = false;
        return Promise.reject(e);
      });
    }).then(() => { // validations for email step will be true if all the required fields are available

      validations[this.steps.email] =
        validations[this.steps.emailAddress] &&
        this.userInfo.fname && this.userInfo.lname &&
        ((!this.phoneNumberRequired) || this.userInfo.phone) &&
        ((!this.checkOptinRequired) || this.userInfo.optin) &&
        ((!this.phoneNumberRequired) || this.phoneUtils.validDigits(this.userInfo.phone));
    }).then(() => { // call for smsSubscribe API if sms phone number have valid digit and sms sub is enabled
      if (!!this.smsEnabled && this.phoneUtils.validDigits(this.userInfo.phone) && (!this.smsSubscribed || !this.phoneNumberUsed.includes(this.userInfo.phone))) {
        const uxCompPrefix = this.uxHelper.getUxcompKey('');
        const targetName = localStorage.getItem('inputTracking') ? JSON.parse(localStorage.getItem('inputTracking')) : {};
        const piiEnabled = this.uxHelper.getUxcomp('pii.enabled');
        const leadDisclosureKey = (piiEnabled === true || piiEnabled === 'true')
          ? this.uxHelper.getUxcompKey('pii.step.phone.disclosure')
          : this.uxHelper.getUxcompKey('checkbox.optin');

        let params = {
          phoneNumber: this.userInfo.phone,
          brandId: this.uxComposite.brandId,
          userInfo: {
            lname: this.userInfo.lname,
            fname: this.userInfo.fname,
            email: targetName?.user?.email,
            //Adding productkey in params for vertically smart slick slooce subscription
            productkey: productKey,
          },
          targetInfo: {
            fname: this.selectedPerson?.fName,
            lname: this.selectedPerson?.lName,
            state: this.selectedPerson?.addresses ? this.selectedPerson?.addresses[0]?.state : undefined,
            extId: this.selectedPerson?.extId,
            city: this.selectedPerson?.addresses ? this.selectedPerson?.addresses[0]?.city : undefined,
            age: this.selectedPerson?.age,
            phone: this.selectedPerson?.phones[0]?.number
          },
          searchInfo: {
            fname: targetName?.search?.firstName,
            lname: targetName?.search?.lastName,
            city: targetName?.search?.city,
            state: targetName?.search?.state,
            phone: this?.userInput?.phone,
          },
          uxCompPrefix,
          leadDisclosureKey,
        }
        //serverPaths.smsSubscribe

        return this.serviceHelperService.jsonService.json(customServerPaths.contentSmsSenderSubscribe, {
          params
        }).then(() => { // set subscription sms true & add phone number in used phone number list

          this.smsSubscribed = true;
          this.phoneNumberUsed.push(this.userInfo.phone);
        })
          .catch((err) => {

            LogUtils.error(err);
          });
      }
      return;
    }).then(() => {
      return this.isValidBilling().then(() => {
        validations[this.steps.billing] = true;

      });
    }).catch(() => {
      // consuming catch since we are returning true/false
      flag = false;
    }).then(() => { // stop spinner afrer completing all the validation for step and set flag as true/false based on validation for step
      this.serviceHelperService.spinnerService.unspin();
      if (step === this.steps.email) {
        flag = !!validations[step];
      }

      LogUtils.debug(validations, flag);
      return flag;
    });
  }

  /**
   *
   * @returns resolve promise with true only if email is valid, checking with regex test
   */
  isValidEmail() {

    return Promise.resolve().then(() => {
      if (emailUtils.validateEmail(this.userInfo.email)) {

        return this.checkEmail();
      } else {
        this.validEmail = false;
      }

      return Promise.reject(false);
    })
  }

  /**
   * @fileOverview validate fileds for billing step including creditcard details & zip etc.
   * @returns resolve promise with true only if validations for billing step are all passed
   */
  isValidBilling() {
    const isValidStreet = !this.creditCardInputHelper.showStreetInput || this.creditCardInputHelper.isValidStreet();

    return Promise.resolve().then(() => {
      LogUtils.debug((!this.processing),
        this.checkFcra,
        this.checkTerm,
        this.creditCardInputHelper.isValidFullName(),
        this.creditCardInputHelper.isValidZip(),
        this.creditCardInputHelper.isValidBillingInfo(),
        isValidStreet,
        (this.structuredCommerceOffers[this.mainOfferKey].length > 0)
      );


      if ((!this.processing) &&
        this.checkFcra &&
        this.checkTerm &&
        this.creditCardInputHelper.isValidFullName() &&
        this.creditCardInputHelper.isValidZip() &&
        this.creditCardInputHelper.isValidBillingInfo() &&
        isValidStreet &&
        this.structuredCommerceOffers[this.mainOfferKey].length > 0) {

        return Promise.resolve(true);
      }

      return Promise.reject(false);
    });
  }

  /**
   * @functionOverview setup all the fields required for signup request
   * @param trackingKey optional key for tracking
   * @returns params object have all the properties required by signup request
   */
  getRequestParam(trackingKey = undefined) {
    let fullName = this.creditCardInputHelper.fullName;
    if (fullName) {
      let splitIndex = fullName.indexOf(" ");
      if (splitIndex) {
        this.creditCardInputHelper.billingAddress.firstName = fullName.substr(0, splitIndex).trim();
        this.creditCardInputHelper.billingAddress.lastName = fullName.substr(splitIndex).trim();

        if (!this.userInfo.fname) {
          this.userInfo.fname = this.creditCardInputHelper.billingAddress.firstName;
          this.userInfo.lname = this.creditCardInputHelper.billingAddress.lastName;
        }

      }
    }
    this.userInfo.zip = this.creditCardInputHelper.billingAddress.zip;
    this.creditCardInputHelper.billingAddress.email = this.userInfo.email;
    let contentInfos = this.createContentInfos();

    let offerRuleKeyDetails = [];

    // Hack to get multiple offers on one uxl working
    // Whichever key is first is what the server will use for the selected offer
    offerRuleKeyDetails.push({
      key: this.mainOfferKey,
    });

    this.userInfo.phone = phoneUtils.getValidatedPhoneNumber(this.userInfo.phone);

    const thinMatch = {
      enabled: false,
      thinMatchType: null
    }
    const thinMatchEnabled = this.serviceHelperService.storageService.getSession("thinMatchEnabled");
    if (thinMatchEnabled) {
      thinMatch.enabled = true;
      thinMatch.thinMatchType = this.serviceHelperService.storageService.getSession("thinMatchType")
    }

    let requestParam = {
      hash: this.hash,
      userInput: this.userInput,
      userInfo: this.userInfo,
      ccNumber: this.creditCardInputHelper.ccNumber,
      ccExpMonth: this.creditCardInputHelper.ccExpMonth,
      ccExpYear: this.creditCardInputHelper.ccExpYear,
      ccCvv: this.creditCardInputHelper.ccCvv,
      commerceOfferIds: [],
      commerceOfferDetails: [],
      refer: this.serviceHelperService.referService.getRefer(),
      billingAddress: this.creditCardInputHelper.billingAddress,
      signUp: true,
      uxcId: this.uxComposite.uxConfigId,
      uxlId: this.uxComposite.uxLayoutId,
      contentInfos: contentInfos,
      trackingKey: trackingKey,
      offerRuleKey: this.uxHelper.getUxcompKey(this.offerRulesKey),
      offerRuleKeyDetails: offerRuleKeyDetails,
      uniqueId: `${this?.uxComposite?.brandId}|${this?.userInfo?.email}`,
      thinMatch,
      threeDS: undefined,
    };

    return requestParam;
  }

  /**
   * @functionOverview SUP request params and call the Sale API which will create the order
   * @param trackingKey optional key for tracking
   * @returns
   */
  signup(trackingKey = undefined) {
    // setup all the request params & store user info in local storage
    let requestParam = this.getRequestParam(trackingKey);
    this.serviceHelperService.userInfoService.storeUserInfo();
    this.serviceHelperService.inputTrackingService.storeUserInfo(this.userInfo);

    return Promise.resolve().then(() => { // Call the Sale API then update the uxcomposite and set newly created commerceOrder
      this.isProcessing = true;
      window.scrollTo(0, 0);
    }).then(()=>{
      return this.getChildObject()?.threeDSComponent?.authorize({
        requestParam,
        structuredCommerceOffers: this.structuredCommerceOffers,
      }).catch((e)=>{
        // consume error
        LogUtils.error(e);
      });
    }).then((threeDSReult)=>{
      LogUtils.debug("ThreeDS",threeDSReult);
      requestParam.threeDS = threeDSReult;

      return this.serviceHelperService.commerceService.processNonOptionSale(requestParam).then((responseEvent: ResponseEvent) => {
        responseEvent.getDocs().forEach((doc) => {
          if (doc instanceof UxComposite) {
            this.updateUxComposite(doc);
          } else if (doc instanceof CommerceContent) {
            if (doc.teaser === false) {
              let signupContent = {
                id: doc._id,
                productKey: this.getProductKeyByPageCategory(),
              };

              // set signup Content in a local storage
              this.serviceHelperService.commerceService.setSignupContent(signupContent);
            }
          } else if (doc instanceof CommerceOrder) {
            this.commerceOrder = doc;
          }
        });
        this.errorReason = null;
        this.clearContent();
        this.serviceHelperService.commerceService.updateStorage(null)
        this.showSuccessNotification();
        this.referralCheck();
        this.next();
      });
    }).catch((e) => { // error of the sale request, log errors and remove scripts & update uxComposite
      if (commerceUtils.isNotAcceptableRequestError(e)) {
        LogUtils.info(e);
      } else {
        LogUtils.error(e);
      }
      if (e instanceof ResponseEvent) {
        if (e.reason === ResponseEvent.REASON.activeMember) {
          this.serviceHelperService.commerceService.hide3dsIframe();
          if (this.serviceHelperService.authenticationService.isLoggedIn()) {
            redirectHelper.redirect(this.serviceHelperService, '/' + clientPaths.member);
          } else {
            redirectHelper.redirect(this.serviceHelperService, '/' + clientPaths.memberLogin);
          }
        } else {          
          this.errorReason = e.reason;
          e.getDocs().forEach((doc) => {
            if (doc instanceof UxComposite) {
              this.updateUxComposite(doc);
            }
          });
          // Ban User
          if (e.reason === ResponseEvent.REASON.notValidAccount) {
            LogUtils.debug(this.uxHelper.getUxcomp('pii.location'));
            // not PII mode
            if (this.uxHelper.getUxcomp('pii.location') !== 'sup') {
              this.userInfo.email = '';
              this.failureFlag = true;            
              this.currentStep = this.steps.email;
              this.feedBackFlags[this.currentStep] = true;
              this.goNextStep(this.currentStep);
            }else {
              this.setPillStep(1);
            // // uxHelper.getUxcomp('pii.location')          
            //   this.oldCreditCardInputHelper = this.creditCardInputHelper;
            //   this.creditCardInputHelper = new CreditCardInputHelper();
            //   this.creditCardInputHelper.fullName = this.oldCreditCardInputHelper.fullName;
            //   LogUtils.debug("cc-lb", this.reasonUxcompKey);
            //   try {
            //     this.feedBackFlags[this.currentStep] = false;
            //     this.openCreditCardLB();
            //   } catch (e) {
            //     LogUtils.error("cc-lb", e);
            //   }
            //   this.updateThinMatch();
            }
          }else {

            this.clearPaymentRecords();
            this.failureFlag = true;
            if (e.reasonObj) {
              let reasonActions = [];
              Object.keys(e.reasonObj).forEach((key) => {
                reasonActions.push(e.reasonObj[key].uxcomp);
              });

              if (reasonActions.length > 0) {
                this.reasonUxcompKey = reasonActions[0];
                this.oldCreditCardInputHelper = this.creditCardInputHelper;
                this.creditCardInputHelper = new CreditCardInputHelper();
                this.creditCardInputHelper.fullName = this.oldCreditCardInputHelper.fullName;
                LogUtils.debug("cc-lb", this.reasonUxcompKey);
                try {
                  this.feedBackFlags[this.currentStep] = false;
                  this.openCreditCardLB();
                } catch (e) {
                  LogUtils.error("cc-lb", e);
                }
              }
            }

            // Dev#1548
            // - code.searchedPerson disappears when updating composite after payment. 
            //   This may not be a problem if the payment is successful, 
            //   but if the payment fails, the name display will disappear. So add the line below.
            this.updateThinMatch();
          }
          // - If uxcomposite is updated(this.updateUxComposite(doc)) after payment failure, 
          //   the code.offerAmountsTotal value disappears and the amount is not displayed.
          return this.init();
        }
      } else {
        LogUtils.error(e);
      }
    }).then(() => { // reinitialize afrer success
      // return this.init();
    }).catch((e) => { // log errors
      LogUtils.error(e);
    }).then(() => { // disable the loader boolean & spinner
      this.isProcessing = false;
      this.serviceHelperService.spinnerService.unspin();      
    });
  }

  /**
  *
  * Validate email address and then goes to next step
  */
  validateEmailCustom(email) {
    this.emailVerificationCheck = emailUtils.validateEmail(email);
    return emailUtils.validateEmail(email)
  }

  clearPaymentRecords() {
    this.creditCardInputHelper.ccNumberFormatted = ''
    this.creditCardInputHelper.ccExp4Digits = ''
    this.creditCardInputHelper.ccCvv = ''
    this.creditCardInputHelper.billingAddress.zip = ''
    this.creditCardInputHelper.ccNumber = ''
    this.creditCardInputHelper.ccExpMonth = ''
    this.creditCardInputHelper.ccExpYear = ''
  }

  referralCheck() {
    // referralEnable
    const referralEnabled = this.uxComposite.getUxcomp(`comp.member.referral.enable`);
    if (referralEnabled && referralEnabled === 'true') {
      // signup email check
      let referral = this.serviceHelperService.storageService.getSession('referral');
      if (referral) {
        let refHash = referral.hash;
        let refEmail = referral.email;
        
        //if (refEmail === this.userInfo.email) {
          LogUtils.debug("found referral reward ", refHash, refEmail);
          this.serviceHelperService.storageService.setSession('referral', null);
          try {
            this.serviceHelperService.referralService.referralLinkCustomer(refHash, this.userInfo.email); // refEmail);

            // track report
            let param = {
              page: BaseComponent.PAGE.referral,
              pageType: BaseComponent.PAGE_TYPE.general,
              type: 'referral',
              action: 'joined'  // sent, click, joined
            };
            this.serviceHelperService.trackingService.report(param);  
          }catch(e) {
            LogUtils.error(e);
          }
        //}
      } 
    }

    // shareLink check
    let shareLink = this.serviceHelperService.storageService.getSession('shareLink');
    if (shareLink) {
        this.serviceHelperService.storageService.setSession('shareLink', null);
        try {
          // track report
          let param = {
            page: BaseComponent.PAGE.shareLink,
            pageType: BaseComponent.PAGE_TYPE.general,
            type: 'shareLink',
            action: 'joined'  // click, joined
          };
          this.serviceHelperService.trackingService.report(param);  
        }catch(e) {
          LogUtils.error(e);
        }        
    }
  }

  /**
   * verifcation method for secure 3d payments
   * @param requestParam merchant plugin info including gracePeriod, merchantId
   * @return resolved promise having true if verifcation is successful
   */
  verify3ds(requestParam): Promise<any> {
    return Promise.resolve().then(() => {
      // reject the promise if some required param is missing
      if ((!this.mpi3dsEnabled) || (!this.mpi3dsMerchantId) || (!this.mpi3dsMerchantId[this.creditCardInputHelper.cardType])) {
        return Promise.reject(false);
      }
    }).then(() => { // setup all the request params, call verify3d payment service
      requestParam.mpiMid = this.mpi3dsMerchantId[this.creditCardInputHelper.cardType];
      requestParam.mpiGracePeriod = this.mpi3dsGracePeriod;
      requestParam.mpiHidden = this.mpi3dsHidden;
      const thinMatch = this.getThinMatchObject();
      const sequenceOptions = this.getSequenceOptions();
      return this.serviceHelperService.commerceService.verify3ds(this.structuredCommerceOffers[this.mainOfferKey], requestParam, sequenceOptions);
    }).then((response: ResponseEvent) => {
      /* set up auth <iframe> if after the successful 3d secure enrollment
      */
      let promises = [];
      let commerce3ds: Commerce3ds = response.getSingleDoc();
      let interval, timeout, iframe;
      requestParam.commerce3dsId = commerce3ds._id;

      if (commerce3ds.isEnrolled()) {
        iframe = this.serviceHelperService.commerceService.create3dsAuthIframe(commerce3ds);
        if (iframe) {
          promises.push(new Promise<any>((fulfill, reject) => {
            interval = setInterval(() => {
              if (this.mpi3dsFlag) {
                fulfill(true);
              }
            }, 200)
          }));

          promises.push(new Promise<any>((fulfill, reject) => {
            timeout = setTimeout(() => {
              reject(false);
            }, this.mpi3dsGracePeriod);
          }));
        }
      } else {
        promises.push(true);
      }
      return Promise.race(promises).catch(() => { // resolve only if all of the creations were successfully fulfilled
        // do nothing
      }).then(() => { // Clear intervals and hide iframe on successful creations
        clearInterval(interval);
        clearTimeout(timeout);
        this.serviceHelperService.commerceService.hide3dsIframe();
        try {
          iframe.setAttribute("height", "1");
          iframe.setAttribute("width", "1");
        } catch (e) {
          LogUtils.error("failed hiding 3ds iframe", e);
        }
      });
    }
    );
  }

  /**
   * @param uxComposite need to be updated got from success or failure sale request
   */
  updateUxComposite(uxComposite: UxComposite) {
    this.setCodeToUxComposite(uxComposite);
    this.serviceHelperService.pageService.setUxComposite(this, uxComposite);
    this.serviceHelperService.uxcService.setUxComposite(uxComposite);
  }

  /**
   * Hide the 3d secure  iframe & go to the next page
   */
  next() {
    this.serviceHelperService.commerceService.hide3dsIframe();
    return this.goNextPage(undefined, undefined, this.commerceOrder ? "/" + this.commerceOrder._id : null);
  }

  /**
   * clear the progressor & sessionStorage
   */
  clearContent() {
    this.serviceHelperService.peopleSearchService.clear();
  }

  /**
   * @functionOverview this will manage the status of fake progressor
   */
  executeProgress() {
    this.currentProgressorExecutionCount++;
    if (this.currentProgressorExecutionCount < this.maxProgressorExecutionCount) {
      return Promise.resolve().then(() => {
        if (this.fakeProgressors.current.duration > 0) {
          return this.fakeProgressors.current.start();
        }
      }).then(() => {
        return this.serviceHelperService.progressorService.endCurrentProgress(timeUtils.getTimestamp());
      }).then(() => {
        this.serviceHelperService.progressorService.setCurrentProgress(timeUtils.getTimestamp());

        if (!this.serviceHelperService.progressorService.hasFinishedProgressors()) {
          return this.executeProgress();
        }
      }).catch((e) => {
        LogUtils.error(e);
      })
    } else {
      // This always happen if RR is skipped.
      // LogUtils.error("Max progressor execution count reached", this.currentProgressorExecutionCount);
      return Promise.resolve();
    }
  }

  /**
   * @functionOverview is called on SUP next button and call the sale/signup function after the successful validations
   * @param currentStep sup step can be 'emailAddess' or 'billing'
   * @param trackingKey optional key for tracking
   */

  goNextStep(currentStep, trackingKey = undefined) {
    return Promise.resolve().then(() => {
      // validate required fields for the step & call smsSubscribe API
      return this.isValidStep(currentStep);

    }).then((flag: any) => {
      // setup required params, validations and call the singup/sale function
      this.feedBackFlags[currentStep] = true;

      const param = {
        type: "submit",
        step: currentStep,
        validation: flag,
        thinMatch: this.getThinMatchObject(),
        optin: false, fcra: false, term: false, optinUrl: '',
        validations: this.getValidations(),
        trackingKey: trackingKey
      };

      if (currentStep === this.steps.email) {
        param.optin = this.userInfo.optin;
        param.optinUrl = location.href;
      } else {
        param.fcra = this.checkFcra;
        param.term = this.checkTerm;
      }

      this.serviceHelperService.trackingService.report(param)
        .catch((e) => {
          LogUtils.error(e);
        });

      if (flag) {
        this.addEmail();
        if (currentStep === this.steps.email) {
          this.serviceHelperService.userInfoService.storeUserInfo();
          this.serviceHelperService.inputTrackingService.storeUserInfo(this.userInfo);
          if (!this.creditCardInputHelper.fullName) {
            if (this.userInfo.fname) {
              this.creditCardInputHelper.fullName = this.userInfo.fname;
            }
            if (this.userInfo.lname) {
              this.creditCardInputHelper.fullName += " " + this.userInfo.lname;
            }
          }
          this.currentStep = this.steps.billing;

        } else if (currentStep === this.steps.billing) {
          if (this.creditCardInputHelper.showStreetInput) {
            this.creditCardInputHelper.billingAddress.bogusFields.street1 = false; 
          } else {
            if (!this.creditCardInputHelper.setDummyAddress(this.uxComposite)) {
              this.creditCardInputHelper.billingAddress.street1 = '';
            }
            if (!this.oldCreditCardInputHelper.setDummyAddress(this.uxComposite)) {
              this.oldCreditCardInputHelper.billingAddress.street1 = '';
            }
            this.creditCardInputHelper.billingAddress.bogusFields.street1 = true; 
          }

          return this.signup(trackingKey).then((res) => {

            this.smsSubscribed = false;
          }).catch(er => {
            LogUtils.error(er);
          });
        }
      } else {
        LogUtils.debug(this.feedBackFlags);
      }
      return flag;
    }).then((flag) => {
      if (this.currentStep === this.steps.billing) {
        setTimeout(() => {
          if (this.stillInThisPage()) {
            try {
              if (this.uxHelper.getUxcomp('pii.multi.step.mode') == 'PROFILE_TEASER') {
                behaviorHelper.goToHash("#secure-title"); 
              } else {
                behaviorHelper.goToHash("#step-billing");
              }
            } catch(e) {}
          }
        }, 0);
      }
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  /**
   * this set/creare content info for request param  i.e content of the person choosed from the srp and product info
   * @returns contnent info for the candidate search
   */
  createTargetContentInfo() {
    let contentInfo = {
      productId: this.contentProductId,
      productKey: this.getProductKeyByPageCategory(),
      data: {
        content: this.serviceHelperService.peopleSearchService.getContentInfoBySelectedObject(this.selectedPerson, this.getProductKeyByPageCategory(), this.commerceContent ? this.commerceContent.content : null),
        contentInfo: this.commerceContent ? this.commerceContent.content : undefined,
      }
    };

    return contentInfo;
  }

  createIdProtectionContentInfo() {
    /**  if any idProtection products available add then add one of it in content info, so then-
     * on signup content processor for idProtection will be executed
    */
    let products = []
    for (let key in idProtectionProductKeys) {
      const productIds = this.uxComposite.getUxcomp(`comp.member.${key}.product.ids`)
      if (productIds && Array.isArray(productIds) && productIds?.length > 0) {
        products.push(productIds[0])
      }
    }
    if (products.length > 0) {
      return {
        productId: products[0],
        productKey: this.PMAX_PROVIDER_TYPES.customerEnrollment,
        data: {}
      }
    }
    return null
  }

  /**
   * this set/create required content info for the candidate, content like choosed person info from search result, providers and type
   * @returns object having required content info for the searched person
   */
  createContentInfos() {
    let contentInfos = [this.createTargetContentInfo()];
    let candidateContentInfo;
    if (this.selectedPerson && this.selectedPerson.providers && this.getProductKeyByPageCategory() === peopleSearchProductKeys.nameSearch) {
      candidateContentInfo = { // Candidate
        productId: this.contentProductId,
        productKey: this.getProductKeyByPageCategory(),
        data: {
          userInfo: this.userInfo,
          userInput: this.userInfo, // for candidate search
          content: {
            teaser: true,
            subType: PeopleSearchContentHelper.COMMERCE_CONTENT_SUBTYPE.candidate,
            perPage: 100,
            providers: this.selectedPerson.providers,
            productKey: this.getProductKeyByPageCategory()
          }
        }
      };
      contentInfos.push(candidateContentInfo);
    }

    const idProtectionContentInfos: any = this.createIdProtectionContentInfo()

    if (idProtectionContentInfos) {
      contentInfos = [...contentInfos, idProtectionContentInfos]
    }

    return contentInfos;
  }

  /**
   * This function is to record user email and other meta deta i.e brand, uxc and personal detail before opt-in for sale/sign-up
   */
  addEmail() {
    if (this.userInfo.email) {
      let meta = {
        userInfo: this.userInfo,
      };
      let extraInfo: any = {};
      if (this.userInfo.optin) {
        extraInfo.optin = true;
        extraInfo.optinUrl = location.href;
      }

      if (this.userInfo.email) {
        this.serviceHelperService.emailService.addEmail(this.uxComposite.brandId, this.userInfo.email, "guest", meta, extraInfo).catch((e) => {
          LogUtils.error("email add failed");
        });
      }
    }
  }

  /**
   * validation for email address
   * @returns true only if, email is valid both from validateEmail Regex and through mail check API
   */
  checkEmail() {
    return Promise.resolve().then(() => {
      if (this.userInfo.email) {
        if (this.userInfo.email) {
          return this.serviceHelperService.emailService.checkEmailAddress(this.userInfo.email).then((responseEvent: ResponseEvent) => {
            this.validEmail = true;
            return responseEvent.data;
          }).catch((err) => { LogUtils.error(err) });
        }
      }
      return Promise.reject(false);
    }).catch((e) => {
      if (e != -false) {
        LogUtils.error(e);
      }
      this.validEmail = false;
      return Promise.reject(e);
    });
  }

  /**
   * this will be called after successful sale/signup send notification message the user with sucess messages it get from uxcomp
   */
  showSuccessNotification() {
    let message = this.uxHelper.getUxcomp("success.message");
    let duration = this.uxHelper.getUxcomp("success.message.duration");
    if (message && duration) {
      this.serviceHelperService.alertService.success(message, duration, true, this.serviceHelperService.alertService.POSITION.top);
    }
  }

  /**
   *
   * @returns all the validation object having validation status of all the properties needed for signup
   */
  getValidations() {
    let validations: any = {};

    validations.fullname = this.creditCardInputHelper.isValidFullName();
    validations.zip = this.creditCardInputHelper.isValidZip();
    validations.billing = this.creditCardInputHelper.isValidBillingInfo();
    validations.ccnumber = this.creditCardInputHelper.isValidCCNumber();
    validations.cccvv = this.creditCardInputHelper.isValidCCCvv();
    validations.ccexp = this.creditCardInputHelper.isValidCCExp();

    validations.fnamecount = this.getLetterCount(this.userInfo.fname);
    validations.lnamecount = this.getLetterCount(this.userInfo.lname);
    validations.emailcount = this.getLetterCount(this.userInfo.email);
    validations.emailformat = emailUtils.validateEmail(this.userInfo.email);

    validations.ccnumbercount = this.getLetterCount(this.creditCardInputHelper.ccNumber);
    validations.cvvcount = this.getLetterCount(this.creditCardInputHelper.ccCvv);
    validations.ccexpcount = this.getLetterCount(this.creditCardInputHelper.ccExp4Digits);
    validations.cczipcount = this.getLetterCount(this.creditCardInputHelper.billingAddress.zip);

    return validations;
  }

  getLetterCount(value: string) {
    let length = 0;
    if (value && isString(value)) {
      length = value.length;
    }

    return length;
  }

  /**
   * this track the user event i.e user input details on sup
   * @param $event is an event object to get event info of the clicked html elment
   */
  trackEventPii($event) {
    let stepNo: number, stepInfo: string;
    if ($event == "phone" || $event == "email") {
      stepNo = 1;
      stepInfo = $event == "phone" ? "phoneSelected" : "emailSelected";
    } else if ($event.target.id == "step4_phone_input" || $event.target.id == "step4_email_input") {
      stepNo = 4;
      stepInfo = $event.target.name
    } else if ($event.target.id == "step2EmailInput" || $event.target.id == "phone") {
      stepNo = 2
      stepInfo = $event.target.name
    } else if ($event.target.id == "step2_password_input" || $event.target.id == "step2_repassword_input") {
      stepNo = 3
      stepInfo = $event.target.name
    } else if ($event.target.id == "firstName" || $event.target.id == "lastName") {
      stepNo = 5
      stepInfo = $event.target.name
    }
    this.serviceHelperService.inputTrackingService.storeUserInfo(this.userInfo);
    let param: any = {
      stepNo,
      stepInfo
    };
    super.trackEvent($event, param);
  }

  trackEvent($event) {
    if (this.creditCardInputHelper.billingAddress.zip) {
      this.userInfo.zip = this.creditCardInputHelper.billingAddress.zip;
    }
    this.serviceHelperService.inputTrackingService.storeUserInfo(this.userInfo);
    let validations: any = this.getValidations();
    let param: any = {
      step: this.currentStep,
      validations: validations,
      value: null,
    };
    super.trackEvent($event, param);
  }

  // On SUP2 submission this set fullname for billing info with user fname & lname we got from SUP1
  setBillingNameFromAccountName() {
    this.creditCardInputHelper.fullName = `${this.userInfo.fname} ${this.userInfo.lname}`;
  }

  // this open the modal for payment info
  openCreditCardLB() {
    let param: any = { type: "lightbox", uxcompKey: this.reasonUxcompKey };
    this.serviceHelperService.trackingService.report(param).catch((e) => {
      LogUtils.error(e);
    });

    $("#cc-lb").modal({ backdrop: 'static', keyboard: false });
  }

  closeCreditCardLB() {
    $("#cc-lb").modal("hide");
  }

  switchToOldCreditCardInputHelper() {
    this.creditCardInputHelper = this.oldCreditCardInputHelper;
  }

  getFormattedTodayDate() {
    let date = new Date();
    let month = stringUtils.pad(date.getMonth() + 1, 2, "0");
    let dom = stringUtils.pad(date.getDate(), 2, "0");
    return `${month}/${dom}/${date.getFullYear()}`;
  }

  /**
 *
 * convert provided phone number to valid format otherwise return ""
 */
  formatInput(event) {
    let formatted = "";
    let value = event.target.value;
    if (value) {
      value = value.replace(/(?:(?:^[^0-9]*1*)|(?:[^0-9]))/g, "");
      let length = value.length;
      if (length > 0) {
        formatted = "(" + value.substring(0, 3);
      }
      if (length > 3) {
        formatted += ") " + value.substring(3, 6);
      }
      if (length > 6) {
        formatted += "-" + value.substring(6, 10);
      }
    }
    this.userInfo.phone = formatted;
  }

  // validate the each charater on input
  keyValidate(event) {
    return event.charCode >= 48 && event.charCode <= 57;
  }

  // phone num field validations including empty and valid digits validations
  isPhoneFieldError(piiEnabled = false) {
    let flag = false;

    if (piiEnabled) {
      return (!this.userInfo.phone || !this.phoneUtils.validDigits(this.userInfo.phone))
    }

    if ((!this.phoneNumberRequired) && (!this.userInfo.phone)) {
      // do nothing
      return;
    } else if (this.feedBackFlags[this.steps.email] && (!this.userInfo.phone || !this.phoneUtils.validDigits(this.userInfo.phone))) {
      flag = true;
    }

    return flag;
  }

  formatString(event, key) {
    // const replaced = event.replace(/[0-9]/g, '');
    const replaced = event;
    if (key === 'lname') {
      this.userInfo.lname = replaced;
    } else if (key === 'fname') {
      this.userInfo.fname = replaced;
    }
    return replaced;
  }

  hideGlobalSpinner() {
    return ['ux_5_0'].includes(this.theme);
  }

  async setOffer(offerKey: string): Promise<void> {
    await this.offerService.setMainOfferKey(this.uxComposite, offerKey);

    await this.initOffers();

    this.theme = this.uxHelper.getTheme();
  }

  getThinMatchObject() {
    return this.offerService.getThinMatchObject();
  }

  getSequenceOptions(): SequenceOptions {
    return this.offerService.getSequenceOptions();
  }

  getLocationByAreaCode(): string {
    return this.addressFields?.getAdministrativeAreaName(this.userInputState || this.commerceContent?.content[0]?.contentInfo?.state)
  }

  get isThinMatchEnabled(): boolean {
    return this.serviceHelperService.storageService.getSession("thinMatchEnabled")
  }

  private updateThinMatch() {
    this.isThinMatchEnable = this.uxHelper.getValue("comp.sales.name-search.thinMatch.enabled")
    const targetName = localStorage.getItem('inputTracking') ? JSON.parse(localStorage.getItem('inputTracking')) : {};
    // let targetName = this.serviceHelperService.storageService.get("inputTracking");
    this.userInputFullName = (targetName?.search?.firstName || targetName?.target?.firstName) + ' ' + (targetName?.search?.lastName || targetName?.target?.lastName);
    this.userInputState = targetName?.search?.state || targetName?.target?.state;

    if (this.isThinMatchEnable) {      
      this.uxHelper.uxComposite.setCode('thinMatchEnabled', this.isThinMatchEnable);
      this.uxHelper.uxComposite.setCode('searchedPerson', this.userInputFullName);
      this.uxHelper.uxComposite.setCode('searchedPersonState', targetName?.search?.state);
    }
  }

  // filterInput(event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   inputElement.value = inputElement.value.replace(/[^a-zA-Z0-9_\-\s]/g, '');
  //   event.target.value = inputElement.value;
  // }
}
